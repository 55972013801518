// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";
import Billing from "views/Dashboard/Billing.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import SignOut from "views/Pages/SignOut.js";
import ClickLink from "views/Pages/ClickLink.js";
import AddAlertModal from "components/Modal/modal";
import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/user",
    hide: true
  },
  {
    path: "/filters",
    name: "Filters",
    
    icon: <StatsIcon color="inherit" />,
    component: Tables,
    layout: "/user",
  },
  {
    path: "/billing",
    name: "Billing",
    
    icon: <CreditIcon color="inherit" />,
    component: Billing,
    layout: "/user",
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/user",
      },
      {
        path: "/signin",
        name: "Sign In",
        
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        secondaryNavbar: false,
        layout: "/auth",
        hide: true
      },
      {
        path: "/signup",
        name: "Sign Up",
        
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
        hide: true
      },
      {
        path: "/clickLink",
        name: "link",
        
        icon: <DocumentIcon color="inherit" />,
        secondaryNavbar: true,
        component: ClickLink,
        layout: "/auth",
        hide: true
      },
      {
        path: "/addAlert",
        name: "addAlert",
        
        icon: <DocumentIcon color="inherit" />,
        secondaryNavbar: true,
        component: AddAlertModal,
        layout: "/auth",
        hide: true
      },
      {
        path: "/signout",
        name: "Logout",
        icon: <RocketIcon color="inherit" />,
        component: SignOut,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
