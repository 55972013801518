import React, {useEffect, useState, useRef} from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  FormControl,
  ModalBody,
  Input,
  useDisclosure,
  ModalCloseButton,
  Lorem,
  ModalFooter
} from "@chakra-ui/react";
import CustomModal from "components/Modal/modal";
import { BellIcon, AddIcon } from "@chakra-ui/icons";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesTableRow from "components/Tables/TablesTableRow";
import GQApi from "api/graphql";
function Tables() {
  const textColor = useColorModeValue("gray.700", "white");

  const [alerts, setAlerts] = useState([]);
  
  // const finalRef = React.useRef()
  useEffect(async () => {
    fetchAlerts()
  }, [])

  async function fetchAlerts ()  {
    let alerts = await GQApi.GetUserAlerts()

    setAlerts(alerts)
  }

  
  
  return<>
  

    
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <Flex>
        <CardHeader p="6px 0px 22px 0px"  >
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            My Filters
          </Text>
          <Spacer />
          <CustomModal
        reload={() => fetchAlerts()}
      />
          {/* <Button onClick={onOpen}> 
            <AddIcon/>
          </Button> */}
          
        </CardHeader>
       </Flex>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th pl="0px" color="gray.400">
                  Link
                </Th>
                <Th color="gray.400">
                  Active
                </Th>
               
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {alerts.map((alert) => {
                return (
                  <TablesTableRow
                      name={alert.name}
                      url={alert.url }
                      active={true}
                      id={alert.id}
                      reload={() => fetchAlerts()}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>

  </> 
}

export default Tables;
