import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import GQApi from "api/graphql";
function TablesTableRow(props) {
  const { url, name,id, active, reload } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  async function deleteAlert() {
    await GQApi.DeleteAlert(id)
    reload()
  }
  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name ? name : url}
            </Text>
          </Flex>
        </Flex>
      </Td>

    
      <Td>
        <Badge
          bg={active === true ? "green.400" : bgStatus}
          color={active === true ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {active === true ? "Active" : "Inactive"}
        </Badge>
      </Td>
      
      <Td>
        <Button p="0px" bg="transparent" variant="no-hover" onClick={() => {window.open( url, '_blank', 'noopener,noreferrer')}} >
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            View
          </Text>
        </Button>
        <Button p="10px" bg="transparent" variant="no-hover" onClick={() => {deleteAlert()}} >
          <Text
            fontSize="md"
            color="red.400"
            fontWeight="bold"
            cursor="pointer"
          >
            Delete
          </Text>
        </Button>
      </Td>
      
    </Tr>
  );
}

export default TablesTableRow;
