export const createAlert = /* GraphQL */ `
  mutation CreateAlert($input: CreateAlertInput!) {
    createAlert(input: $input) {
      url,
      name
    }
  }
`;

export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert($input: DeleteAlertInput!) {
    deleteAlert(input: $input) {
      id
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      telegramId
      discordWebhook
      useTelegram
    }
  }
`;