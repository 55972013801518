import React, { useState, useEffect } from "react";

// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Input,
  Image,
  Link,
  Switch,
  Text,
  useColorModeValue,
  GridItem
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import ImageArchitect1 from "assets/img/ImageArchitect1.png";
import ImageArchitect2 from "assets/img/ImageArchitect2.png";
import ImageArchitect3 from "assets/img/ImageArchitect3.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import {
  FaCube,
  FaFacebook,
  FaInstagram,
  FaPenFancy,
  FaPlus,
  FaTwitter,
} from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import { useAuth } from "../../auth-context/auth.context";
import GQApi from "../../api/graphql.js";

const initialState = {
  id: "",
  telegramId: "",
  discordWebhook: "",
  plan: null,
  useTelegram: false,
};

function Profile() {
  const [userSettings, setUserSettings] = useState(initialState);
  const [saving, setSaving] = useState(false);
  let { user } = useAuth();
  
  useEffect(async () => {
    let settings = await GQApi.GetUserSettings(user.username)
    setUserSettings(settings.data.listUsers.items[0])
  }, [])
  
  function setInput(key, value) {
    setUserSettings({ ...userSettings, [key]: value });
  }

  const switchTelegramDiscord = (event) => {
    setUserSettings({ ...userSettings, useTelegram: !event.target.checked });
  };

  async function updateUserSettings() {
    setSaving(true)
    try {
      await GQApi.UpdateUserSettings(userSettings)
    } catch (err) {
      console.log("error updating user:", err);
    }
    setSaving(false)
  }

  // Chakra color mode
  // retrieveUserSettings()
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const borderProfileColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );
  const emailColor = useColorModeValue("gray.400", "gray.300");

  return (
    <Flex direction="column" >
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
      >
        <Box
          bgImage={ProfileBgImage}
          w="100%"
          h="300px"
          borderRadius="25px"
          bgPosition="50%"
          bgRepeat="no-repeat"
          position="relative"
          display="flex"
          justifyContent="center"
        >
          <Flex
            direction={{ sm: "column", md: "row" }}
            mx="1.5rem"
            maxH="330px"
            w={{ sm: "90%", xl: "95%" }}
            justifyContent={{ sm: "center", md: "space-between" }}
            align="center"
            backdropFilter="saturate(200%) blur(50px)"
            position="absolute"
            boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
            border="2px solid"
            borderColor={borderProfileColor}
            bg={bgProfile}
            p="24px"
            borderRadius="20px"
            transform={{
              sm: "translateY(45%)",
              md: "translateY(110%)",
              lg: "translateY(160%)",
            }}
          >
            <Flex
              align="center"
              mb={{ sm: "10px", md: "0px" }}
              direction={{ sm: "column", md: "row" }}
              w={{ sm: "100%" }}
              textAlign={{ sm: "center", md: "start" }}
            >
              <Avatar
                me={{ md: "22px" }}
                src={avatar4}
                w="80px"
                h="80px"
                borderRadius="15px"
              />
              <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
                <Text
                  fontSize={{ sm: "lg", lg: "xl" }}
                  color={textColor}
                  fontWeight="bold"
                  ms={{ sm: "8px", md: "0px" }}
                >
                  {user.attributes.email}
                </Text>
                <Text
                  fontSize={{ sm: "sm", md: "md" }}
                  color={emailColor}
                  fontWeight="semibold"
                >
                  Account : {user.signInUserSession.accessToken.payload["cognito:groups"][0]}
                </Text>
              </Flex>
            </Flex>
            <Flex
              direction={{ sm: "column", lg: "row" }}
              w={{ sm: "100%", md: "50%", lg: "auto" }}
            >
              <Button p="0px" bg="transparent" _hover={{ bg: "none" }}>
                <Flex
                  align="center"
                  w={{ sm: "100%", lg: "135px" }}
                  bg="hsla(0,0%,100%,.3)"
                  borderRadius="15px"
                  justifyContent="center"
                  py="10px"
                  boxShadow="inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)"
                  border="1px solid gray.200"
                  cursor="pointer"
                >
                  <Icon as={FaCube} me="6px" />
                  <Text fontSize="xs" color={textColor} fontWeight="bold">
                    OVERVIEW
                  </Text>
                </Flex>
              </Button>
              
            </Flex>
          </Flex>
        </Box>
      </Box>
      
      <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap="22px">
        <GridItem colSpan={1} colStart={2}>
        <Card p="16px" >
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Bot Settings
            </Text>
          </CardHeader>
          <CardBody px="10px">
            <Flex direction="column">
              <Text fontSize="sm" color="gray.500" fontWeight="600" mb="20px">
                Telegram ID
              </Text>
              <Flex align="center" mb="20px">
                <Input placeholder="Telegram Id" value={userSettings.telegramId} onChange={(event) => setInput("telegramId", event.target.value)}/>
              </Flex>
              
              <Text
                fontSize="sm"
                color="gray.500"
                fontWeight="600"
                m="6px 0px 20px 0px"
              >
                Discord Webhook
              </Text>
              <Flex align="center" mb="20px">
                <Input value={userSettings.discordWebhook} placeholder="Discord Webhook"  onChange={(event) => setInput("discordWebhook", event.target.value)}/>
              </Flex>
              <Flex align="center" mb="20px">
                <Switch isChecked={!userSettings.useTelegram} onChange={switchTelegramDiscord} colorScheme="teal" me="10px" />
                <Text
                  noOfLines={1}
                  fontSize="md"
                  color="gray.500"
                  fontWeight="400"
                >
                  Use Discord to receive alerts ?                      
                </Text>
              </Flex>
              <Button isLoading={saving} onClick={() => {updateUserSettings()}} colorScheme='teal' variant='solid'>
               Save
              </Button>
            </Flex>
          </CardBody>
        </Card>
        </GridItem>
      </Grid>
      
      
     
    </Flex>
  );
}

export default Profile;
