import { STRIPE_API } from "../config/constant";

class StripeApi {
    
    static GetStripePortal = (id) => {
        const url = STRIPE_API + "/portal" 
        const requestOptions = {
            method: 'POST',
            
            body: JSON.stringify({ user: id })
        }
        return fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {window.open( data.url, '_blank', 'noopener,noreferrer')} );

    };

    static GetStripeInvoice = (id, priceId) => {
        const url = STRIPE_API + "/invoice" 
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ user: id, price: priceId })
        }
        fetch(url, requestOptions)
        .then(response => response.json())
        .then(data =>  {window.open( data.url, '_blank', 'noopener,noreferrer')} );

    };

    
  
}

export default StripeApi
