import { Auth } from 'aws-amplify';

class AuthApi {
  static Login = (email,password) => {
    return Auth.signIn(email, password);
  };

  static Register = (data) => {
    return Auth.signUp(data);
  };

  static Logout = (data) => {
    return Auth.signOut();
  };
}

let base = "users";

export default AuthApi;
