export const getUser = /* GraphQL */ `
  query GetUser($filter: ModelUserFilterInput!) {
    listUsers(filter: $filter) {
      items {
        id
        discordWebhook
        useTelegram
        telegramId
        plan
      }
    }
  }
`;


export const listAlerts = /* GraphQL */ `
  query ListAlerts {
    listAlerts {
      items {
        url
        id
        name
      }
    }
  }
`;