import React, {useEffect, useState, useRef} from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  FormLabel,
  Thead,
  Tr,
  Button,
  useColorModeValue,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  FormControl,
  ModalBody,
  Input,
  useDisclosure,
  ModalCloseButton,
  Lorem,
  ModalFooter
} from "@chakra-ui/react"; 
import { BellIcon, AddIcon } from "@chakra-ui/icons";
import GQApi from "api/graphql";
function AddAlertModal(props) {
    const [url, setUrl] = useState({url: "", name:""});
    const {reload} = props
    async function addAlert() {
        GQApi.AddAlert(url).then(()=> {reload()})
        
        onClose()
        
        

    }
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <>
        <Button colorScheme="red" size="xs" onClick={onOpen}>
          <AddIcon/>
        </Button>
        <Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Alert</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>

            <FormControl mt={4}>
              
              <Input onChange={(event) => {
                    setUrl(prevState => ({...prevState, url: event.target.value}));
                  }} placeholder='Filter Url' />
                <Input onChange={(event) => {
                     setUrl(prevState => ({...prevState, name: event.target.value}));
                  }} placeholder='Filter name' />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={()=> {addAlert()}}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    );
  };
export default AddAlertModal