export const API_SERVER = "http://localhost:5000/api/";
export const awsmobile = {
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_appsync_region": process.env.REACT_APP_AWS_REGION,
    "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID ,
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    "aws_appsync_authenticationType": process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_AUTH_TYPE,

};
export const STRIPE_API = process.env.REACT_APP_STRIPE_ENDPOINT
export const STRIPE_BASIC_PLAN = process.env.REACT_APP_BASIC_PLAN
export const STRIPE_PREMIUM_PLAN = process.env.REACT_APP_PREMIUM_PLAN
export const STRIPE_PRO_PLAN = process.env.REACT_APP_PRO_PLAN


