// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
// Assets

// Custom components

import React from "react";
import { SiHive, SiMarketo, SiMicrosoft } from 'react-icons/si'
import { STRIPE_BASIC_PLAN , STRIPE_PREMIUM_PLAN, STRIPE_PRO_PLAN} from '../../config/constant'


import { PricingCard } from '../../components/Pricing/PricingCard'
import { ActionButton } from '../../components/Pricing/ActionButton'
import { useAuth } from "../../auth-context/auth.context";

import StripeApi from "api/stripe";
function Billing() {
  let { user } = useAuth();
  // Chakra color mode
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#dee2e6", "gray.500");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  async function redirectPriceInvoice(priceId) {
    try {
      await StripeApi.GetStripeInvoice(user.username, priceId)
    } catch (err) {
      console.log("error updating user:", err);
    }
    
  }

  async function redirectStripePortal() {
    try {
      await StripeApi.GetStripePortal(user.username)
    } catch (err) {
      console.log("error fecthing user portal:", err);
    }
    
  }
  return (
    <Box
    as="section"
    bg={useColorModeValue('gray.50', 'gray.800')}
    py="14"
    px={{
      base: '4',
      md: '8',
    }}
  >
      <Flex align="flex-end" justify="center" fontWeight="extrabold"  my="8">

        <Button md="100px" justifyContent='center' onClick={() => {redirectStripePortal()}} >
          <Heading size="1xl" fontWeight="inherit" lineHeight="0.9em">
          Gérer mes abonnements.
          </Heading>
        </Button>


      </Flex>
    <SimpleGrid
      columns={{
        base: 1,
        lg: 3,
      }}
      spacing={{
        base: '8',
        lg: '0',
      }}
      maxW="7xl"
      mx="auto"
      justifyItems="center"
      alignItems="center"
      justify='center'
    >
      <PricingCard
        data={{
          price: '9.99€',
          name: 'Basic',
          features: [
            '3 Alertes personalisées',
            'Recherches ciblées',
            'Alertes Télégram',
            'Alertes Discord (Sera supprimé)',
          ],
        }}
        icon={SiMicrosoft}
        button={
          <ActionButton variant="outline" borderWidth="2px" onClick={() => {redirectPriceInvoice(STRIPE_BASIC_PLAN)}}>
            Acheter
          </ActionButton>
        }
      />
      <PricingCard
        zIndex={1}
        isPopular
        transform={{
          lg: 'scale(1.05)',
        }}
        data={{
          price: '19.99€',
          name: 'Premium',
          features: [
            '10 Alertes personalisées',
            'Recherches ciblées',
            'Alertes Télégram',
            'Alertes Discord ',
            "Suivi des achats/reventes (à venir)"
          ],
        }}
        icon={SiHive}
        button={<ActionButton onClick={() => {redirectPriceInvoice(STRIPE_PREMIUM_PLAN)}} >Acheter</ActionButton>}
      />
      <PricingCard
        data={{
          price: '29.99€',
          name: 'Pro',
          features: [
            'Alertes personalisées illimitées',
            'Recherches ciblées',
            'Alertes Télégram',
            'Alertes Discord',
            "Suivi des achats/reventes (à venir)"
          ],
        }}
        icon={SiMarketo}
        button={
          <ActionButton variant="outline" borderWidth="2px" onClick={() => {redirectPriceInvoice(STRIPE_PRO_PLAN)}}>
            Acheter
          </ActionButton>
        }
      />
      {/* <Center >
      <Box bg='tomato' height='80px' verticalAlign={"center"}>
        
      </Box>
      </Center> */}
      
     
    </SimpleGrid>

    
  </Box>
  );
}

export default Billing;
