import { API, graphqlOperation } from "aws-amplify";
import { getUser, listAlerts } from "./graphql/queries.js";
import { updateUser, deleteAlert, createAlert } from "./graphql/mutations.js";



class GQApi {
    //////////// USERS //////////////////
    static GetUserSettings = (id) => {
    
      return API.graphql(
        graphqlOperation(getUser, { filter: { id: { eq: id } } })
      );

    };

    static UpdateUserSettings = (data) => {
      return API.graphql(
         graphqlOperation(updateUser, {
         input: {
           id: data.id,
           telegramId: data.telegramId,
           discordWebhook: data.discordWebhook,
           useTelegram: data.useTelegram,
         },
       })
     )
     };

     //////////// ALERTS //////////////////

    static GetUserAlerts = () => {
      return API.graphql(graphqlOperation(listAlerts))
        .then((data) => {return data.data.listAlerts.items});
      
    }

    static DeleteAlert = (id) => {
      return API.graphql(graphqlOperation(deleteAlert, { input: { id: id } }));
    }

    static AddAlert = (alert) => {
      return API.graphql(
        graphqlOperation(createAlert, { input: { url: alert.url, name: alert.name } })
      );
    }
  
}

export default GQApi 